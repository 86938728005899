<template>
  <div class="redis-body">
    <div class="progress-bar-container">
      <progress class="progress is-small is-primary" max="100" ref="loadingBar" style="display:none;"></progress>
    </div>
    <div class="redisConf">

      <div class="search-site-container">
        <input id="siteText" class="tag-textfield input is-small is-primary" type="text" placeholder="Site name (ex: WIF_NLISO)." v-model="currentSite" v-on:keyup.enter="researchBySite"/>
        <input class="button is-small is-success" type="button" v-on:click="researchBySite()" value="Display"/>
      </div>

      <div class="refresh-group">
        <span class="tag is-info">{{ lastRefreshtime.toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', second: 'numeric', timeZoneName: 'short'}) }} ({{globalTagsListToDisplay.length}} tag(s) displayed) - channel : {{ currentChannel }}</span>
      </div>
      
    </div>
    <div class="redis-result">

      <div class="list-title-head">
        <h3>Selected tags</h3>
        <div class="button is-small is-success" v-on:click="refreshSelectedListManualy()">
          <font-awesome-icon :icon="'sync-alt'" />
        </div>
        <span class="tag is-info">Most recent tag source date: {{ mostRecentlySelectedDate?.toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', second: 'numeric', timeZoneName: 'short'}) }}</span>
        <label class="checkbox">
          <input ref="autorefreshCheck" type="checkbox" v-on:change="autoRefresh()">
          Auto refresh
        </label>
      </div>
      <table class="table is-striped is-hoverable">
        <caption></caption>
        <thead>
          <th class="sortable-column raw-column" v-on:click="sortColumn(RedisColumnName.RAW_NAME, TableName.SELECTED, false)">Source tag</th>
          <th class="sortable-column preproc-column" v-on:click="sortColumn(RedisColumnName.PREPROC_NAME, TableName.SELECTED, false)">Preproc tag</th>
          <th class="sortable-column realtime-column" v-on:click="sortColumn(RedisColumnName.REALTIME_NAME, TableName.SELECTED, false)">Realtime tag</th>
          <th class="action-column">Action</th>
        </thead>
        <tbody>
          <tr class="tab-body-item" v-for="(result, index) in selectedTagListToDisplay" v-bind:key="result.preprocTag.name">
            <td class="tab-result-tag-raw">
              <p class="tag-label" :title="result.sourceTag.name">
                {{result.sourceTag.name}}
              </p>
              <p class="tag-value" :title="(result.tagSite + ' - ' + result.sourceTag.date?.toLocaleString({ day: 'numeric', month: 'numeric', year: '2-digit', hour: 'numeric', minute: '2-digit', second: 'numeric'}) + ' - ' + valueFormat(result.sourceTag.val))">
                {{ result.tagSite }} - {{ result.sourceTag.date?.toLocaleString({ day: 'numeric', month: 'numeric', year: '2-digit', hour: 'numeric', minute: '2-digit', second: 'numeric'}) }} - {{valueFormat(result.sourceTag.val)}}
              </p>
            </td>

            <td class="tab-result-tag-preproc">
              <p class="tag-label" :title="result.preprocTag.name">
                {{result.preprocTag.name}}
              </p>
              <p class="tag-value" :title="result.preprocTag.val">
                {{valueFormat(result.preprocTag.val)}}
              </p>
            </td>

            <td class="tab-result-tag-realtime" v-if="result.realtimeTag.name !== null">
              <p class="tag-label"  :title="result.realtimeTag.name">
                {{result.realtimeTag.name}}
              </p>
              <p class="tag-value" :title="result.realtimeTag.val">
               {{valueFormat(result.realtimeTag.val)}}
              </p>
            </td>
            <td class="tab-result-tag-realtime error-text" v-else>
              <p class="tag-label"  :title="Unknow">
                Unknow
              </p>
              <p class="tag-value">-</p>
            </td>

            <td><input class="removeTag button is-xsmall is-danger" type="button" value="X" v-on:click="removeSelectedTag(index, result.preprocTag.name)" /></td>
          </tr>        
        </tbody>
      </table>

      <div class="list-title-head">
        <h3>Global tags list</h3>
        <div class="button is-small is-success" v-on:click="RefreshGlobalTagListManualy()">
          <font-awesome-icon :icon="'sync-alt'" />
        </div>
        <div class="search-tag-container">
          <span class="icon">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
              </svg>
          </span>
          <div>
              <input id="site-search-txtfld" class="site-textfield" type="text" placeholder="Tag name..." v-on:keyup="filterGlobalTagsListToDisplay()" v-model="currentTag"/>
              <input class="clr-global-site-search" type="button" v-on:click="clearGlobalListSearch()" value="x"/>
          </div>
        </div>
        <span class="tag is-info">Most recent tag source date: {{ mostRecentlyGlobalDate?.toLocaleString({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', second: 'numeric', timeZoneName: 'short'})}}</span>
      </div>
      <table class="table is-striped is-hoverable">
        <caption></caption>
        <thead>
          <th class="sortable-column raw-column" v-on:click="sortColumn(RedisColumnName.RAW_NAME, TableName.GLOBAL, false)">Source tag</th>
          <th class="sortable-column preproc-column" v-on:click="sortColumn(RedisColumnName.PREPROC_NAME, TableName.GLOBAL, false)">Preproc tag</th>
          <th class="sortable-column realtime-column" v-on:click="sortColumn(RedisColumnName.REALTIME_NAME, TableName.GLOBAL, false)">Realtime tag</th>
          <th class="action-column">Action</th>
        </thead>
        <tbody>
          <tr class="tab-body-item" v-for="(result, index) in globalTagsListToDisplay" v-bind:key="result.preprocTag.name">
            <td class="tab-result-tag-raw">
              <p class="tag-label" :title="result.sourceTag.name">
                {{result.sourceTag.name}}
              </p>
              <p class="tag-value" :title="(result.sourceTag.date?.toLocaleString({ day: 'numeric', month: 'numeric', year: '2-digit', hour: 'numeric', minute: '2-digit', second: 'numeric'}) + ' - ' + valueFormat(result.sourceTag.val))">
                {{ result.sourceTag.date?.toLocaleString({ day: 'numeric', month: 'numeric', year: '2-digit', hour: 'numeric', minute: '2-digit', second: 'numeric'}) }} - {{valueFormat(result.sourceTag.val)}}
              </p>
            </td>

            <td class="tab-result-tag-preproc">
              <p class="tag-label" :title="result.preprocTag.name">
                {{result.preprocTag.name}}
              </p>
              <p class="tag-value" :title="valueFormat(result.preprocTag.val)">
                {{valueFormat(result.preprocTag.val)}}
              </p>
            </td>

            <td class="tab-result-tag-realtime" v-if="result.realtimeTag.name !== null">
              <p class="tag-label"  :title="result.realtimeTag.name">
                {{result.realtimeTag.name}}
              </p>
              <p class="tag-value" :title="result.realtimeTag.val">
                {{valueFormat(result.realtimeTag.val)}}
              </p>
            </td>
            <td class="tab-result-tag-realtime error-text" :title="Unknow" v-else>
              <p class="tag-label" :title="Unknow">
                Unknow
              </p>
              <p class="tag-value">
                -
              </p>
            </td>

            <td><input class="button is-xsmall is-success" type="button" value="Select" v-on:click="addSelectedTag(index, result.preprocTag.name)" /></td>
          </tr>        
        </tbody>
      </table>

    </div>
  </div>
</template>
<script>
import redisApi from '../lib/api/redis.service.js';
import arrayUtilities from '../Utilities/array.utilities.js';
import dateTimeLuxon from '../lib/time/dateTimeLuxon.js';

export const RedisColumnName = {
  PREPROC_NAME: 0,
  REALTIME_NAME: 1,
  RAW_NAME : 2
}

export const TableName = {
  SELECTED: 0,
  GLOBAL: 1
};

export default {
  data: function () {
    return {
      RedisColumnName,
      TableName,
      globalTableCurrentSortColumnName: RedisColumnName.RAW_NAME,
      selectedTableCurrentSortColumnName: RedisColumnName.RAW_NAME,
      claims: '',
      currentSite: "",
      currentTag: "",
      currentGlobalTagListSite: "",
      currentTags: "",
      textFieldValue: "",
      globalTagsList: [],
      globalTagsListToDisplay: [],
      selectedTagList: [],
      selectedTagListToDisplay: [],
      checkboxRefresh: null,
      interval: null,
      lastRefreshtime: dateTimeLuxon.nowBaseFormat(),
      loadingBar: null,
      globalTableCurrentSortDir:'asc',
      selectedTableCurrentSortDir:'asc',
      mostRecentlyGlobalDate: null,
      mostRecentlySelectedDate: null,
      siteDetails: [],
      currentChannel: ""
    }
  },
  async created () {
    this.claims = await this.$auth.getUser();
  },
  mounted: function () {
    this.checkboxRefresh = this.$refs.autorefreshCheck;
    this.loadingBar = this.$refs.loadingBar;
  },
  methods: {
    //Main methods
    async researchBySite () {
      try{
        this.currentChannel = "";
        const accessToken = await this.$auth.getAccessToken();
        const siteDetails = await redisApi.getSiteDetailsFromSite(this.currentSite, accessToken);
        this.currentChannel = siteDetails.Channel;
        this.displayLoadingBar(true);
        this.globalTagsList = [];
        this.globalTagsListToDisplay = [];

        if (this.currentSite !== null && this.currentSite !== "") {
          await this.GetSiteGlobalTagList(this.currentSite);

          this.currentGlobalTagListSite = this.currentSite;
          this.sortColumn (RedisColumnName.RAW_NAME, TableName.GLOBAL, true);
          this.displayLoadingBar(false);
        }
        else {
          this.displayLoadingBar(false);
          this.$notify({
                      group: 'foo',
                      title: 'Empty field',
                      text: 'Please specify a site name (ex: WIF_FRMPS)',
                      duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                      type: 'error'
                    });
        }
      }catch(err){
        this.displayLoadingBar(false);
        this.$notify({
                      group: 'foo',
                      title: 'Error',
                      text: err,
                      duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                      type: 'error'
                    });
      }
    },
    async getTagValues () {
      try{
        const accessToken = await this.$auth.getAccessToken();
        let resultTagsMapping = await redisApi.getTagsMapping(this.selectedTagList, accessToken);
        this.selectedTagListToDisplay = [];
        if (resultTagsMapping && resultTagsMapping.length > 0) {
          this.selectedTagListToDisplay = resultTagsMapping;
          this.lastRefreshtime = dateTimeLuxon.nowBaseFormat()
        }
        resultTagsMapping = await redisApi.getTagsValuesWithoutSiteName(this.selectedTagListToDisplay, accessToken);
        if (resultTagsMapping && resultTagsMapping.length > 0) {
          this.selectedTagListToDisplay = resultTagsMapping;
          this.lastRefreshtime = dateTimeLuxon.nowBaseFormat();
        }
        
      let lastDate = Math.max.apply(null, this.selectedTagListToDisplay.filter(
                                                          function(f) {
                                                              if (f.sourceTag.date !== null && f.sourceTag.date !== 'undefined' && f.sourceTag.date !== 'null') {
                                                                return true;
                                                              }else{
                                                                return false;
                                                              }
                                                            }).map(function(e) {
                                                              return new Date(e.sourceTag.date);
                                                          }));
      if (lastDate <= 0) {
        this.mostRecentlySelectedDate = null;
      } else {
        this.mostRecentlySelectedDate = dateTimeLuxon.baseFormat(lastDate);
      }

        this.displayLoadingBar(false);
      }catch(err){
        this.displayLoadingBar(false);
        this.$notify({
                      group: 'foo',
                      title: 'Error',
                      text: err,
                      duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                      type: 'error'
                    });
      }
    },
    async GetSiteGlobalTagList(site) {
      const accessToken = await this.$auth.getAccessToken();
      this.globalTagsList = await redisApi.getSiteTagMapping(site, accessToken);

      let sourceTagMap = await redisApi.getSourceTagsFromSite(site, accessToken);

      let tempResultList = [];
      var i,j,tempRealtimeTagNames,chunkRealtime = 100;
      for (i=0,j=this.globalTagsList.length; i<j; i+=chunkRealtime) {
        tempRealtimeTagNames = this.globalTagsList.slice(i,i+chunkRealtime);
        let resultTagsMapping = await redisApi.getTagsValues(tempRealtimeTagNames, sourceTagMap, accessToken);
        if (resultTagsMapping && resultTagsMapping.length > 0) {
          Array.prototype.push.apply(tempResultList, resultTagsMapping);
        } 
        else {
          Array.prototype.push.apply(tempResultList, tempRealtimeTagNames);
        }
      }
      this.globalTagsList = tempResultList;
      this.globalTagsListToDisplay = this.globalTagsList;
      this.filterGlobalTagsListToDisplay();
      this.lastRefreshtime = dateTimeLuxon.nowBaseFormat();

      let dateList = this.globalTagsList.map(function(e) { 
                                                          return new Date(e.sourceTag.date);
                                                          });
      this.mostRecentlyGlobalDate = dateTimeLuxon.baseFormat(Math.max.apply(null, dateList));
    },
    // Selection
    addSelectedTag (index) {
      this.displayLoadingBar(true);
      let currentSelectedTag = this.globalTagsListToDisplay[index];
      
      this.selectedTagList = arrayUtilities.AddStringTagIfNotPresent(this.selectedTagList, currentSelectedTag);
      this.selectedTagList.sort();

      this.getTagValues();
    },
    removeSelectedTag (index, preprocValue) {
      this.selectedTagListToDisplay.splice(index, 1);
      this.selectedTagList.splice(this.selectedTagList.findIndex(t => t.preprocTag.name === preprocValue), 1);
      
      let lastDate = Math.max.apply(null, this.selectedTagListToDisplay.filter(
                                                            function(f) {
                                                                if (f.sourceTag.date !== null && f.sourceTag.date !== 'undefined' && f.sourceTag.date !== 'null') {
                                                                    return true;
                                                                }else{
                                                                  return false;
                                                                }
                                                              }).map(function(e) {
                                                                return new Date(e.sourceTag.date);
                                                            }));
      if (lastDate <= 0) {
        this.mostRecentlySelectedDate = null;
      } else {
        this.mostRecentlySelectedDate = dateTimeLuxon.baseFormat(lastDate);
      }
    },
    //Sorts
    sortColumn (columnName, table, init) {
      switch (table) {
        case TableName.GLOBAL:
          if(!init && columnName === this.globalTableCurrentSortColumnName) {
            this.globalTableCurrentSortDir = this.globalTableCurrentSortDir==='asc'?'desc':'asc';
          }
          this.globalTableCurrentSortColumnName = columnName;

          this.sortedColumn(table);
          break;
        case TableName.SELECTED:
          if(!init && columnName === this.selectedTableCurrentSortColumnName) {
            this.selectedTableCurrentSortDir = this.selectedTableCurrentSortDir==='asc'?'desc':'asc';
          }
          this.selectedTableCurrentSortColumnName = columnName;

          this.sortedColumn(table);
          break;
        default:
          this.displayLoadingBar(false);
          this.$notify({
                      group: 'foo',
                      title: 'Error',
                      text: "Unknow column",
                      duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                      type: 'error'
                    });
          break;
      }
      
    },
    sortedColumn (table) {
      switch (table) {
        case TableName.GLOBAL:
          this.globalTagsListToDisplay.sort((a,b) => {
            let modifier = 1;
            let aSetup = null;
            let bSetup = null;

            switch (this.globalTableCurrentSortColumnName) {
              case RedisColumnName.RAW_NAME:
                aSetup = a.sourceTag.name;
                bSetup = b.sourceTag.name;
                break;
              case RedisColumnName.PREPROC_NAME:
                aSetup = a.preprocTag.name;
                bSetup = b.preprocTag.name;
                break;
              case RedisColumnName.REALTIME_NAME:
                aSetup = a.realtimeTag.name;
                bSetup = b.realtimeTag.name;
              break;
              default:
              break;
            }

            if(this.globalTableCurrentSortDir === 'desc') modifier = -1;

            if (aSetup === bSetup) {
                return 0;
            }
            else if (aSetup === null) {
                return 1 * modifier;
            }
            else if (bSetup === null) {
                return -1 * modifier;
            }
            else {
                return aSetup < bSetup ? -1 * modifier : 1 * modifier;
            }
          });
          break;
        case TableName.SELECTED:
          this.selectedTagListToDisplay.sort((a,b) => {
            let modifier = 1;
            let aSetup = null;
            let bSetup = null;

            switch (this.selectedTableCurrentSortColumnName) {
              case RedisColumnName.RAW_NAME:
                aSetup = a.sourceTag.name;
                bSetup = b.sourceTag.name;
                break;
              case RedisColumnName.PREPROC_NAME:
                aSetup = a.preprocTag.name;
                bSetup = b.preprocTag.name;
                break;
              case RedisColumnName.REALTIME_NAME:
                aSetup = a.realtimeTag.name;
                bSetup = b.realtimeTag.name;
              break;
              default:
              break;
            }

            if(this.selectedTableCurrentSortDir === 'desc') modifier = -1;

            if (aSetup === bSetup) {
                return 0;
            }
            else if (aSetup === null) {
                return 1 * modifier;
            }
            else if (bSetup === null) {
                return -1 * modifier;
            }
            else {
                return aSetup < bSetup ? -1 * modifier : 1 * modifier;
            }
          });
          break;
        default:
          this.displayLoadingBar(false);
          this.$notify({
                      group: 'foo',
                      title: 'Error',
                      text: "Unknow column",
                      duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                      type: 'error'
                    });
          break;
      }
     
    },
    // Filter
    filterGlobalTagsListToDisplay() {
      if(this.currentTag != ""){
        this.globalTagsListToDisplay = this.globalTagsList.filter(f => f.sourceTag.name?.toLowerCase().includes(this.currentTag.toLowerCase()) || f.preprocTag.name.toLowerCase().includes(this.currentTag.toLowerCase()) || f.realtimeTag.name.toLowerCase().includes(this.currentTag.toLowerCase()));
      }else{
        this.globalTagsListToDisplay = this.globalTagsList;
      }
    },
    clearGlobalListSearch() {
      this.currentTag = "";
      this.globalTagsListToDisplay = this.globalTagsList;
    },
    // Utilities
    displayLoadingBar(isVisible) {
      if(isVisible) {
        this.loadingBar.style.display = "block";
      }
      else {
        this.loadingBar.style.display = "none";
      }
    },
    valueFormat(currentValue) {
      var regexp = /^[+-]?[0-9]+([,.][0-9]+)?$/g;
      if(regexp.test(currentValue)) {
        let troncatValue = Number(currentValue).toFixed(2);
        return troncatValue;
      }
      else {
        return currentValue;
      }
    },
    //Refresh
    async autoRefresh () {
      if (this.checkboxRefresh.checked) {
        this.interval = setInterval(async function () {
          this.refreshSelectedListManualy();
        }.bind(this), 5000);
      } else {
        clearInterval(this.interval);
      }
    },
    async refreshSelectedListManualy() {
      try{
        this.displayLoadingBar(true);
        const accessToken = await this.$auth.getAccessToken();
        let resultTagsMapping = await redisApi.getTagsMapping(this.selectedTagList, accessToken);
        this.selectedTagListToDisplay = [];
        if (resultTagsMapping && resultTagsMapping.length > 0) {
          this.selectedTagListToDisplay = resultTagsMapping;
          this.lastRefreshtime = dateTimeLuxon.nowBaseFormat();
        }
        resultTagsMapping = await redisApi.getTagsValuesWithoutSiteName(this.selectedTagListToDisplay, accessToken);
        if (resultTagsMapping && resultTagsMapping.length > 0) {
          this.selectedTagListToDisplay = resultTagsMapping;
          this.lastRefreshtime = dateTimeLuxon.nowBaseFormat();
        }
        this.displayLoadingBar(false);
      }catch(err){
        this.displayLoadingBar(false);
        this.$notify({
                      group: 'foo',
                      title: 'Error',
                      text: err,
                      duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                      type: 'error'
                    });
      }
    },
    async RefreshGlobalTagListManualy() {
      if (this.currentGlobalTagListSite !== null && this.currentGlobalTagListSite !== "") {
        this.displayLoadingBar(true);
        await this.GetSiteGlobalTagList(this.currentGlobalTagListSite);
        this.filterGlobalTagsListToDisplay();
        this.sortColumn (this.globalTableCurrentSortColumnName, TableName.GLOBAL, true);
        this.displayLoadingBar(false);
      }
    },
    //Authentification
    async isAuthenticated () {
      this.authenticated = await this.$auth.isAuthenticated();
    }
  }
}

</script>
<style lang="scss" scoped>
$primary: #287ab1;
$hover: #a5d7f8;
$trLight: #ecf8ff;
$error: #c70000;
$warning: #d39b00;
$success: rgb(31, 155, 0);

/* Bulma custom */

.redis-body {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-start;

  .progress-bar-container {
      height: 12px;
      width: 100%;
    
    .progress {
      margin:0;
      border-radius: 0;
    }
  }

  .redisConf {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin:5px 0 0 0;
    padding: 5px;

    .search-site-container {
      width: 30%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;

      #siteText {
        border-color: $success;
        border-radius: 3px 0 0 3px;
      }

      input {
        border-radius: 0 3px 3px 0;
      }
    }

    .refresh-group {
      width: 70%;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;

      > p {
        margin: 0 5px 0 0;
      }

      label.checkbox {
        width: auto;
        display: block;
        height: 100%;
        text-align: end;
        padding-right: 5px;
        margin-left: 5px;
      }
    }
  }

  .redis-result {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0;
    padding: 5px;

    .list-title-head {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-end;
      height: 30px;
      width: 100%;

      .search-tag-container {
        width: 30%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 4px;


        div {
          width: 100%;
            
          #site-search-txtfld {
            width: 90%;
            border: none;
            border-bottom: 1px solid #999999;
            margin-left: 5px;
            outline: none;
          }

          .clr-global-site-search {
              border: none;
              background-color: #ffffff; 
              outline: none;
              cursor: pointer;
          }
        
        }

        input {
          border-radius: 0 3px 3px 0;
        }
      }

      h3 {
        height: 100%;
        margin: 0 3px 0 0;
      }
    
      div.button {
        margin: 0 3px 0 0;
      }

      .tag {
          margin: 0 3px 0 0;
      }

      label {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        input {
          margin-right:5px ;
        }

        p {
          margin:0 5px;
        }
      }
    }

    .table {
      font-size: 0.8em;
      margin-bottom: 10px;

      caption {
          text-align: left; 
      }

      thead {
        width: 100%;

        th {
          width: 100%;
          padding: 2px 10px;

          &.raw-column, &.preproc-column, &.realtime-column {
            width: calc((100% - 140px) / 3);
          }

          &.action-column{
            width: 60px;
          }

          &.tagsourcedate-column {
            width: 130px;
          }

          &.tag-site-column{
            width: 100px;
          }

          &.sortable-column {
              cursor: pointer;
          }
        }
      }
/**/      
      tbody {
        width: 100%;

        tr {
          width: 100%;

          td {
            word-break: break-all;
            padding: 2px 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            //white-space: nowrap;

            &.tab-result-tag-raw, &.tab-result-tag-preproc, &.tab-result-tag-realtime {
            width: calc((100% - 160px) / 3);
            }

            p.tag-label {
              width: 100%;
              margin: 0;
              padding: 0;
            }

            p.tag-value {
              width: 100%;
              font-weight: bold;
            }

            input.button {
              height: 30px;
              padding: 0 5px;
              font-size: 0.8em;
            }

            input.removeTag {
              width: 30px;
            }

            svg {
                height: 20px;
                width: auto;
            }
            &.error-text {
                color: $error;
                font-weight: 500;
            }
          }
        }
      }
/**/
    }
  }
}
</style>
